import * as Sentry from '@sentry/browser'

// same DSN like production, but without http password, could also be injected like the SENTRY_RELEASE

if (typeof window.ENVIRONMENT !== 'undefined') {
  Sentry.init({
    dsn: 'https://d47a987888b34f9fab9ea7ae1bbe4162@o391529.ingest.sentry.io/5237841',
    release: window.SENTRY_RELEASE,
    environment: window.ENVIRONMENT,
    denyUrls: ['localhost', '127.0.0.1'],
  })
}

window.Sentry = Sentry
